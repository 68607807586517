import { logger } from './logger';
import { GameWithObjectBadges } from '@/components/Game/GameRenderingModule';

const LocalStorageProvider = (() => {
	const data: Record<string, string | undefined> = {};
	const prefix: string = 'evo:';
	let lsUnavailable = false;
	let blockedByGdpr: boolean | null = null;

	// Check if localStorage is available
	try {
		window.localStorage.getItem('');
	} catch (e) {
		console.error('[LocalStorageProvider] Local storage is not available', e);
		lsUnavailable = true;
	}

	const setItem = (key: string, value: any, noprefix = false) => {
		if (blockedByGdpr) {
			return;
		}
		// Prefix the key if needed
		if (!noprefix) {
			key = `${prefix}${key}`;
		}
		if (lsUnavailable) {
			data[key] = String(value);
		} else {
			window.localStorage.setItem(key, String(value));
		}
	};

	const getItem = (key: string, noprefix = false): string => {
		if (!noprefix) {
			key = `${prefix}${key}`;
		}
		if (lsUnavailable) {
			return data[key] ?? '';
		}
		const value = window.localStorage.getItem(key);
		// Check if the retrieved value is null or undefined
		if (!value || value === 'undefined' || value === 'null') {
			return '';
		}
		return value;
	};
	const removeItem = (key: string, noprefix = false) => {
		if (!noprefix) {
			key = `${prefix}${key}`;
		}
		if (lsUnavailable) {
			delete data[key];
		} else {
			window.localStorage.removeItem(key);
		}
	};

	const setBlockedByGdpr = (consent: boolean) => {
		blockedByGdpr = !consent;
	};

	return {
		getItem,
		setItem,
		removeItem,
		setBlockedByGdpr,
	};
})();

export const LocalStorageService = LocalStorageProvider;

export enum LSKeys {
	gamePlayCount = 'ark_game_play_count',
	lastGamePlay = 'ark_last_gameplay',

	// gdpr cmp form values
	popupTargetingConsent = 'popup:targetingConsent',
	popupGDPRCookieConsent = 'popup:gdprCookieConsent',

	// whole platform cmp business logic resulting values
	targetingConsent = 'ark_modules:targetingConsent',
	gdprCookieConsent = 'ark_modules:gdprCookieConsent',

	isNonGDPR = 'ark_is_non_gdpr',
	userConsentDetails = 'ark_user_consent_details',
	gpcDataSharingOptIn = 'ark_gpc_data_sharing_opt_in',
}

// Function to add a new object to the localStorage item
export const addRecentItemToLocalStorage = (itemKey: string, item: GameWithObjectBadges, noPrefix?: boolean) => {
	// Get the existing data from localStorage (old iteration had an object)
	const existingData = LocalStorageService.getItem(itemKey);

	// Initialize an empty array
	let parsedArray: GameWithObjectBadges[] = [];

	// If existingData is an object (not an array), convert it into an array
	if (existingData) {
		try {
			const parsedData = JSON.parse(existingData);

			// Check if parsed data is an array, if not, convert it into an array
			parsedArray = Array.isArray(parsedData) ? parsedData : [parsedData];
		} catch (error) {
			logger.error('Error parsing localStorage data:', error);
			parsedArray = [];
		}
	}

	// Check if the item already exists in the array
	const existingItemIndex = parsedArray.findIndex((game: GameWithObjectBadges) => game.id === item?.id);

	// If the item exists, then remove it
	if (existingItemIndex !== -1) {
		parsedArray.splice(existingItemIndex, 1);
	}

	// Add the new item at the beginning of the array
	parsedArray.unshift(item);

	// Limit the array to 10 items
	if (parsedArray.length > 10) {
		parsedArray = parsedArray.slice(0, 10); // Keep only the first 10 items
	}

	// Save the updated array to localStorage
	LocalStorageService.setItem(itemKey, JSON.stringify(parsedArray), noPrefix);
};
